*,
*::before,
*::after {
  box-sizing: border-box;
}

.container {
  display: grid;
  min-height: 100vh;
  grid-template-areas:
    'header header'
    'main main';
  grid-template-rows: 55px 1fr;
  grid-template-columns: 200px 1fr;
  grid-gap: 2px;
}

.container.with-sidebar {
  grid-template-areas:
    'header header'
    'nav main';
}

.page-container {
  position: relative;
  grid-area: main;
  display: grid;
}

.hidden {
  display: none;
}
