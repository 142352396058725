header {
  grid-area: header;
  position: relative;
  background-color: #242d33;
  color: #fff;
  font-family: trim, Helvetica, Arial, sans-serif;
  font-size: 16px;
  padding: 8px 16px 8px 8px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.icon-wrapper {
  height: 36px;
}

.icon-wrapper svg {
  height: 100%;
}

.account-options {
  position: absolute;
  z-index: 1;
  background-color: rgb(36, 45, 51);
  top: 100%;
  right: 0px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-image: initial;
  padding: 8px 16px;
  min-width: 160px;
}

.account-options button {
  font-family: oscine, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1;
  font-weight: bold;
  color: rgb(158, 162, 164);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin: 8px 0px;
  background: none;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  text-decoration: none;
  padding: 0px;
}

.account-options button:hover,
.account-options button:hover svg,
.icon-wrapper:hover svg {
  color: #E0E1E1;
}

.account-options button svg {
  height: 30px;
  width: 30px;
  color: rgb(158, 162, 164);
  margin-right: 8px;
}

.dropdown-chevron {
  position: relative;
}

.dropdown-chevron:after {
  content: "";
  width: 10px;
  height: 10px;
  display: block;
  background-color: rgb(36, 45, 51);
  position: absolute;
  top: 92.5%;
  left: 50%;
  transform: translate(-50%, 75%) rotate(45deg);
  z-index: 9999;
  border-color: rgb(255, 255, 255) rgb(255, 255, 255);
  border-top: 1px solid;
  border-left: 1px solid;
}