@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.spinner {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
}

.spinner::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  border: 5px solid;
  border-color: transparent #000 #000;
  border-radius: 100%;
  animation: spin 1s ease infinite;
}

.spinner.active {
  visibility: visible;
  opacity: 1;
}