nav {
  grid-area: nav;
  background-color: #242d33;
  padding: 0px 10px 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

#nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-link {
  color: #fff;
  text-decoration: none;
}

.nav-link:hover {
  text-decoration: underline;
}