.login-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-column: 1 / 3;
  background-color: #3d4549;
  height: 100%;
}

.login-container {
  max-width: 300px;
  margin-top: 32px;
  display: grid;
  justify-items: center;
  background-color: white;
  padding-top: 16px;
}

.login-container svg {
  width: 50px;
}

.login-title {
  margin: 8px 0;
  padding: 0 16px;
  text-transform: uppercase;
  text-align: center;
  color: #242D33;
  font-size: 16px;
  font-family: trim, Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0C161C;
  background-color: #F7FF1A;
  padding: 16px 32px;
  text-transform: uppercase;
  font-family: trim, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  font-style: italic;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

.login-button:hover,
.login-button:focus {
  background-color: #DEE517;
}
